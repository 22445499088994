import {
  Header,
  Hero,
  CTA,
  CookieModal,
  NoMoney,
  DisclaimerModal, 
  TopGames
} from "./components";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Hero />
        <CTA />
        <TopGames/>
        <CookieModal />
        <DisclaimerModal />
      </ThemeProvider>
    </div>
  );
}

export default App;
