// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h4" paragraph>
            Gameslotwin: Your Ultimate Online Gaming Destination</Typography>
            <Typography variant="body1" paragraph>
            Gameslotwin offers the best free online games selection, providing the most fun experience to play alone or with friends. Enjoy instant play without downloads, login, popups, or other distractions. Our games are accessible on desktop, tablet, and mobile, so you can enjoy them at home or on the go. Every month, millions of gamers from around the world play their favorite games on Gameslotwin.

</Typography>
            <Typography variant="body1" paragraph>
            Our journey began with a small team of passionate gamers who wanted to create a platform that offered a seamless and enjoyable gaming experience for everyone. From humble beginnings, we have grown into a vibrant community of gamers and developers dedicated to providing the best online games. Our mission is to create the ultimate online playground that is free and open to all.

</Typography>
            <Typography variant="body1" paragraph>
            We are always looking to improve and expand our offerings. If you have any questions, feedback, or if you are a game developer looking to succeed on the web, please reach out to us at info@gameslotwin.com.

</Typography>
            <Typography variant="h4">
            Responsible Gaming Reminder
                        </Typography>
            <Typography variant="body1" paragraph>
            Practicing casino gaming for fun does not imply future success in real money gambling. It's important to remember that gambling involves risks, and excessive or uncontrolled gambling can have negative consequences. Always gamble responsibly and within your means.
            </Typography>
            <Typography variant="h4">
            Dive into the Fun Today                        </Typography>
            <Typography variant="body1" paragraph>
            Gameslotwin games are playable on any device, whether you’re using a desktop, tablet, or mobile. Experience the same high-quality gameplay wherever you are.

</Typography>
            <Button
              variant="contained"
              sx={{backgroundColor: "secondary"}}
              component={Link}
              to="/games"
            >
                GO TO GAMES
            </Button>


           
          </Box>
        </Grow>
      </Container>
    </div>
  );
}

export default CTA;
