import React from "react";
import { topGames } from "../data/games";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent"; // Import CardContent
import Button from "@mui/material/Button"; // Import Button
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function TopGames() {
  return (
    <Container maxWidth="lg">
      <Typography textAlign="center" gutterBottom variant="h3" fontWeight="900">
        Top picks
      </Typography>
      <Typography textAlign="center" gutterBottom variant="h5">
        The most popular games among users
      </Typography>

      <Grid container spacing={3}>
        {topGames.map((game) => (
          <Grid item xs={12} sm={4} key={game.id}>
            <Card
              sx={{
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
                backgroundColor: "primary.main400",
                color: "#ffffff",
              }}
            >
              <CardActionArea 
              component={Link}
                to={`/game/${game.id}`}
                style={{ position: "relative" }}
              >
               
                <svg style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "30px",
                    height: "30px",
                    zIndex: "10", 
                    fill: "#02ff60",
                  }}  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 47.94 47.94">
<path  d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
	c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
	c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
	c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
	c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
	C22.602,0.567,25.338,0.567,26.285,2.486z"/>
</svg>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="200"
                  image={game.image}
                  title={game.title}
                  style={{ fontSize: "14px" }}
                />
                <CardContent textAlign="center">
                  <Typography variant="h6" component="div" gutterBottom>
                    {game.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    // component={Link}
                    // to={`/game/${game.id}`}
                    mx="auto"
                  >
                    Play now
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        sx={{display: "block", textAlign: "center", margin: "3rem auto 0", backgroundColor: "primary.main400"}}
        component={Link}
        to="/games"
      >
        See all games
      </Button>
    </Container>
  );
}
