import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Header, GamesList, Footer, GamePage, Terms, PrivacyPolicy, About, Contacts, MyList } from "./components";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header/>
        <main className="main">
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/games" element={<GamesList />} />
            <Route path="/game/:gameId" element={<GamePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/mylist" element={<MyList />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
