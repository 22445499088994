// src/components/GameDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {games, topGames} from '../data/games.js'; // Adjust the path to match your project structure

function GamePage() {
  const { gameId } = useParams(); // Retrieve the game ID from the route parameter

  // Find the selected game based on the game ID
  let selectedGame = games.find((game) => game.id === parseInt(gameId));
  selectedGame =
    selectedGame || topGames.find((game) => game.id === parseInt(gameId));

  if (!selectedGame) {
    return (
      <h2 style={{ textAlign: "center", margin: "3rem auto" }}>
        Game not found
      </h2>
    );
  }

  return (
    <div>
      <div style={{ paddingTop: "1rem" }}>
        <Typography variant="h4" align="center" gutterBottom>
          {selectedGame.title}
        </Typography>
        <iframe
          title={selectedGame.title}
          src={selectedGame.iframeUrl}
          width="100%"
          height="500"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default GamePage;
